@import "tailwindcss/base";

@layer base {
  html {
    @apply bg-beige;
  }
}

@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@500;700&display=swap");

.loader-exit {
  @apply opacity-100;
}

.loader-exit-active {
  @apply opacity-0 transition-opacity duration-300;
}

.loader-exit-done {
  @apply hidden;
}

input,
select {
    padding: 0.5rem;
    border: 1px solid #7a374a;
    border-radius: 10px;
    display: block;
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    outline-style: none;
    font-size: 1.2rem;
    transition: all 0.2s;
}

input:focus-visible {
    border: 1px solid #fff;
}

.submitButton {
  background-color: #7a374a;
  color: #FBF9F3;
  padding: 0.6rem;
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
  margin-top: 2rem;
  transition: all 0.2s;
  border: none;

}

.submitButton:hover {
  background-color: #7a374a80;
}

.carousel-image-transition {
  transition: opacity 1000ms ease-in-out;
}

.countdown-bar {
  height: 3px;
  background-color: #f3f3f3;
  position: relative;
  width: 100%;
}

.countdown-fill {
  height: 100%;
  background-color: #f5d52f80;
  position: absolute;
}
